<template>
  <router-view />
  <div class="form-wrapper">
    <!-- logo -->
    <div id="logo">
      <img
        class="logo"
        src="@/assets/image/logo-gesdoc-overdrive.png"
        alt="image"
      />
    </div>
    <!-- ./ logo -->

    <h5>gesdoc overdrive <span>beta</span></h5>

    <!-- login form -->
    <form @submit.prevent="submitLogin" v-if="!formEsqueci">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          placeholder="Email"
          required
          autofocus
          v-model="email"
          :disabled="validating"
        />
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          required
          v-model="password"
          :disabled="validating"
        />
      </div>
      <div class="form-group d-flex justify-content-between">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input me-2"
            checked=""
            v-model="remeberMe"
          />
          <label class="custom-control-label" for="customCheck1"
            >Lembrar de mim</label
          >
        </div>
        <a href="javascript:;" @click="switchEsqueci">Esqueci minha senha</a>
      </div>
      <button
        type="submit"
        class="btn btn-primary btn-block"
        :disabled="validating"
      >
        Acessar
      </button>
      <div v-if="invalid" class="form-group error">
        {{ errorMessage }}
      </div>
      <pulse v-if="validating" />
    </form>

    <forgot v-if="formEsqueci" @back="switchEsqueci" />

    <div class="mt-4">
      <p>2023 &copy; (PC)² inteligência tecnológica.</p>
    </div>
    <!-- ./ form -->
  </div>
</template>

<script>
import Forgot from './Forgot.vue';
import Pulse from "@/components/Pulse.vue";
import { mapGetters, mapActions } from 'vuex';
import errors from '../../constants/errors';

export default {
  components: {
    Pulse, Forgot
  },
  data() {
    return {
      formEsqueci: false,
      email      : "",
      password   : "",
      invalid    : false,
      validating : false,
      loginData  : false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters(['apiEndpoint']),
    autologin() {
      return this.$store.getters.userId;
    },
    remeberMe() {
      return !!localStorage.getItem('rememberEmail');
    },
  },
  methods: {
    ...mapActions(['login']),
    switchEsqueci() {
      this.formEsqueci = !this.formEsqueci;
    },
    async submitLogin() {
      this.validating = true;
      this.invalid = false;

      const loginData = {
        email: this.email,
        password: this.password,
      };

      try {
        await this.login(loginData);

        if (this.remeberMe) {
          localStorage.setItem("rememberEmail", this.email);
        } else {
          localStorage.removeItem("rememberEmail");
        }
        this.$router.push("/files");
      } catch(error) {
        if (error.message === errors.UNAUTHORIZED) {
          this.errorMessage = 'Usuário ou senha inválidos.';
        } else {
          this.errorMessage = 'Houve um problema ao acessar o servidor. Tente novamente mais tarde.';
        }
        
        this.invalid = true;
        this.password = '';
      } finally {
        this.validating = false;
      }
    },
  },
  created() {
    // If remember me is on, fetch email
    if (localStorage.getItem("rememberEmail")) {
      this.email = localStorage.getItem("rememberEmail");
    }
  }
}
</script>
