<template>
  <form @submit.prevent="submit">
    <p>
      Entre com o seu e-mail para receber as instruções para gerar uma nova senha.
    </p>
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        placeholder="Email"
        required
        autofocus
        v-model="email"
        :disabled="validating"
        :class="{error: !valid}"
      />
    </div>
    <p class="error" v-if="!valid">{{ errorMsg }}</p>
    <p v-if="submitted">Você receberá em sua caixa de e-mail instruções para gerar a nova senha.</p>
    <button
      type="submit"
      class="btn btn-primary btn-block"
      :disabled="validating"
    >
      Recuperar a senha
    </button>
    <a href="javascript:;" @click="this.$emit('back')" v-if="!validating">Voltar</a>
    <div v-if="invalid" class="form-group error">E-mail invalido.</div>
    <pulse v-if="validating" />
  </form>
</template>

<script>
import Pulse from "@/components/Pulse.vue";
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  components: {
    Pulse,
  },
  data() {
    return {
      email     : '',
      valid     : true,
      submitted: false,
      validating: false,
      errorMsg  : '',
    };
  },
  computed: {
    ...mapGetters(['apiEndpoint'])
  },
  methods: {
    validateForm() {
      this.valid = true;

      const validator = require("email-validator");

      if (!validator.validate(this.email)) {
        this.valid = false;
      }
    },
    async submit() {
      this.validating = true;

      this.validateForm();

      if (!this.valid) {
        this.validating = false;
        this.$toast.add({severity:'error', summary: 'Entre com o seu e-mail corretamente.'});
        return;
      }


      const data = {
        email: this.email
      };

      try {
        await axios.post(`${this.apiEndpoint}/users/requestForgotPassword`, data);
        this.email = '';
        this.submitted = true;
      } catch {
        this.$toast.add({severity:'error', summary: 'Houve um erro. Por favor, tente novamente mais tarde.'});
      } finally {
        this.validating = false;
      }
    },
  },
  emits: ["back"]
}
</script>
